.season-calendar-container {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(300px, 1fr)
  ); /* each item will have at least 300px and will stretch to fill available space */
  gap: 20px; /* space between items */
  padding: 20px 10px;

  @media (max-width: 500px) {
    padding: 20px 5px;
  }

  .past-game-container {
    align-self: center;
    display: unset;

    .game-container {
      height: 180px;
    }
  }

  .game-container {
    @media (max-width: 500px) {
      padding: 12px;
    }

    .competition {
      text-wrap: normal;
      font-size: 13px;
      text-align: left;

      .date-time {
        font-size: 13px;
        color: #e32a24;
        font-weight: bold;
        text-align: left;
        margin: 2px 0;
        text-transform: initial;
      }

      @media (max-width: 500px) {
        font-size: 12px;
        padding: 0px;
      }
    }

    .tv-logo {
      height: 30px;
    }

    .teams-container {
      margin-top: 20px;
    }

    .team-name {
      font-size: 12px;
      font-weight: bold;
      text-align: center;
    }

    .team-logo {
      width: 60px;
    }

    .score {
      font-size: 15px;
      padding: 10px 20px;
      flex: 0;
      min-width: 100px;
      margin: 0;
    }
  }
}
