.circle-wrap {
  width: 100px;
  height: 100px;
  background: #e6e2e7;
  border-radius: 50%;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 100px, 100px, 50px);
}

.circle-wrap .circle .mask .fill {
  clip: rect(0px, 50px, 100px, 0px);
  background-color: #ae9767;
}
.circle-wrap .circle .mask.full,
.circle-wrap .circle .fill {
  animation: fill ease-in-out 3s;
  /*transform: rotate(180deg);*/
}

.circle-wrap .inside-circle {
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
}
