.team-row {
  display: grid;
  grid-template-columns: 40px 50px 200px 60px repeat(5, 80px) 60px 60px;
  align-items: center;
  justify-content: center;
  padding: 10px 50px;
  border-radius: 20px;
  background-color: #f2f2f2;
  color: #2a4b9a;
  min-width: 610px;
  font-weight: bold; /* Emphasizes the rank */
  max-width: 890px;
  margin: 10px 0px;

  &.aix-team {
    background-color: #40c0da;
    color: white;

    &:hover {
      background-color: #40c0da;
    }
  }

  &.header {
    background-color: white;
    color: #40c0da;
    font-weight: bold;
    font-size: 12px;

    &:hover {
      background-color: white;
    }
  }

  div {
    padding: 0px 10px;
  }
}

.team-row:hover {
  background-color: #e8f4ff; /* Light blue background on hover */
}

.team-logo img {
  width: 40px; /* Fixed size for logos */
  height: 40px;
  border-radius: 50%; /* Makes the logo circular */
}

.team-name,
.team-points,
.team-matches,
.team-victories,
.team-draws,
.team-defeats,
.team-goals-for,
.team-goals-against,
.team-goal-diff,
.team-part-pts,
.team-part-goals {
  text-align: center; /* Centers text in each column */
}

.team-name {
  text-align: left; /* Team names aligned to the left */
}

.team-rank {
  font-weight: bolder; /* Emphasizes the rank */
  color: #40c0da;
}

.aix-team .team-rank {
  color: white;
}

/* Add responsive behavior */
@media (max-width: 980px) {
  .team-row {
    grid-template-columns: 40px 50px 140px 60px repeat(7, 60px); /* Smaller columns on smaller screens */
    padding: 10px 20px;
  }
}
/* Add responsive behavior */
@media (max-width: 760px) {
  .team-row {
    grid-template-columns: 30px 50px 100px 40px repeat(7, 50px); /* Smaller columns on smaller screens */
    padding: 10px 20px;
  }
}
