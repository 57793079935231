.coming-game-container {
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
    display: flex;
    flex: 1;

    .game-container {
        background-color: #e9e9e9;
        border-radius: 20px;
        padding: 16px;
        flex: 1;
        border: 2px solid black;
    }

    .title {
        display: inline-block;
        padding: 5px 20px;
        border-radius: 20px;
        color: #2b4b9a;
        font-size: 21px;
        font-weight: bold;
    }

    .competition {
        font-weight: bold;
        font-size: 14px;
        color: #e32a24;
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        margin-bottom: 20px;
    }

    .date-time {
        margin: 5px 0;
        font-size: 20px;
        font-weight: bold;

        &:first-letter {
            text-transform: uppercase;
        }
    }

    .stadium {
        color: #666666;
        margin: 5px 0;
    }

    .match-date,
    .location {
        color: #666666;
        margin: 5px 0;
    }

    .teams-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex: 1;
        margin-top: 20px;
    }

    .team {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
    }

    .team-logo {
        width: 80px; /* Adjust size to match your design */
        margin-bottom: 10px;
    }

    .team-name {
        font-weight: bold;
        color: #000000;
        text-transform: uppercase;
        font-size: 18px;
    }

    .score {
        font-size: 28px;
        font-weight: bold;
        margin: 0 20px;
        padding: 12px 25px;
        border-radius: 50px;
        flex: 0.75;
        background-color: #FF0000;
        color: white;
    }

    .tv-channel {
    }

    .tv-logo {
        width: 50px;
    }

    .channel-name {
        font-size: 18px;
        color: #000;
    }
}
