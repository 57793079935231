.past-game-container {
  background-color: #ffffff;
  border-radius: 10px;
  text-align: center;
  display: flex;
  max-width: 500px;
  flex: 1;

  .game-container {
    background-color: #e9e9e9;
    border-radius: 20px;
    padding: 16px;
    flex: 1;
  }

  .title {
    display: inline-block;
    padding: 5px 20px;
    border-radius: 20px;
    color: #2b4b9a;
    font-size: 21px;
    font-weight: bold;
  }

  .date-time {
    margin: 5px 0;
    font-size: 20px;
    font-weight: bold;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  .competition {
    font-weight: bold;
    color: #e32a24;
    text-transform: uppercase;

    &.competition-spaced {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .match-date,
  .location {
    color: #666666;
    margin: 5px 0;
  }

  .teams-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    flex: 1;
  }

  .team {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  }

  .team-logo {
    width: 80px; /* Adjust size to match your design */
    margin-bottom: 10px;
  }

  .team-name {
    font-weight: bold;
    color: #000000;
    text-transform: uppercase;
    font-size: 18px;
  }

  .score {
    font-size: 28px;
    font-weight: bold;
    color: #000000;
    margin: 0 20px;
    background-color: white;
    padding: 12px 25px;
    border-radius: 50px;
    flex: 1;
  }

  .tv-channel {
  }

  .tv-logo {
    width: 50px;
  }

  .channel-name {
    font-size: 18px;
    color: #000;
  }
}
