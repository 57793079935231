.player-card {
  display: flex;
  background: #f4f4f4;
  border-radius: 6px;
  padding: 20px;
  gap: 2rem;
  flex: 1;
  justify-content: center;

  .player-info {
    text-align: center;
    flex: 2;
  }

  .player-photo {
    display: flex;
    justify-content: center;
    margin: 20px;
    align-items: center;

    img {
      width: 340px; /* Adjust size as necessary */
      height: auto;
      border-radius: 50%;
    }
  }

  .player-name {
    margin: 0;
    font-size: 20px;
    color: #333;
    text-align: left;
  }

  .player-position {
    font-size: 14px;
    color: #666;
    text-align: left;
    margin: 5px 0;
  }

  .player-position-img {
    display: flex;
    flex: 1;
    img {
      max-width: 250px;
      height: auto;
    }
  }

  .player-number {
    font-size: 14px;
    color: #b29a6c;
  }

  .player-container-details {
    display: flex;
    margin-top: 20px;
  }

  .player-details {
    font-size: 12px;
    color: #666;
    text-align: left;
    display: flex;
    flex: 1;
    padding: 0px 20px;
    gap: 3rem;
    align-items: center;

    .player-details-left-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 1rem;
    }

    .player-details-right-column {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      flex: 1;
      justify-content: flex-end;
    }

    /* Ensure that all paragraphs have the same line height */
    .player-details-left-column p,
    .player-details-right-column p {
      margin: 0;
      line-height: 1.5rem; /* Adjust this value as needed */
      min-width: 60px;
    }
  }
  .player-stats {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Creates a 6-column grid */
    text-align: center;
    padding: 20px;
    background: #ffffff;
    grid-template-areas:
      ". stat1 stat1 stat2 stat2 ."
      "stat3 stat3 stat4 stat4 stat5 stat5"
      ". stat6 stat6 stat7 stat7 .";
    gap: 10px;
    flex: 3;
  }

  .stat:nth-child(1) {
    grid-area: stat1;
  }
  .stat:nth-child(2) {
    grid-area: stat2;
  }
  .stat:nth-child(3) {
    grid-area: stat3;
  }
  .stat:nth-child(4) {
    grid-area: stat4;
  }
  .stat:nth-child(5) {
    grid-area: stat5;
  }
  .stat:nth-child(6) {
    grid-area: stat6;
  }
  .stat:nth-child(7) {
    grid-area: stat7;
  }

  .stat {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 10px;
  }

  .stat-value {
    font-size: 20px;
    font-weight: bold;
    color: #333;
    width: 90px; /* Diameter of the circle */
    height: 90px; /* Diameter of the circle */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: #eee;
    position: relative;
    z-index: 1;
    flex-direction: column;

    span {
      font-size: 14px;
      font-weight: normal;
    }
  }

  .stat-label {
    font-size: 12px;
    font-weight: bold;
    text-wrap: nowrap;
  }
}
/* Add responsive rules as needed */
@media (max-width: 900px) {
  .player-card {
    margin: 10px auto;
    flex-direction: column;
  }
}
