.reload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reload-button {
  padding: 10px 20px;
  margin-top: 20px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.reload-button:hover {
  background-color: #0056b3;
}
