.season-standing-container {
  font-family: Arial, sans-serif; /* Change to your preferred font */
  border-radius: 8px; /* Optional rounded corners */
  overflow: hidden; /* Ensures the border radius clips the content */
  flex-direction: column;
  justify-content: center;
  text-align: -webkit-center;
  gap: 10px;
  margin: 10px 0px;
  width: 100%;

  @media (max-width: 768px) {
    overflow-x: auto; /* Allows horizontal scrolling */
  }
}
